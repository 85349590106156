import { useState } from "react";
import { List, ListItem, ListItemText, Box } from "@mui/material";
import {
  AdminPanelSettingsOutlined,
  DeleteOutline,
  LogoutOutlined,
  Mail,
} from "@mui/icons-material";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";

import { styles } from "./styles";

import BaseModal from "@/components/Modals/BaseModal/BaseModal";
import DeleteAccountModal from "@/components/Modals/DeleteAccountModal";
import MenuListItem from "@/components/MenuPanel/MenuListItem";
import PanelHeader from "@/components/PanelHeader";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { convertTimeIsoToUs } from "@/utils/globals";
import { EProvider } from "@/types/users";
import { setLoggedUser, removeUser } from "@/store/slices/auth/actions";
import AlertDialog from "@/components/AlertDialog/AlertDialog";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as GoogleIcon } from "&/img/icons/googleLogo_iconColored.svg";
import { ReactComponent as AppleIcon } from "&/img/icons/appleLogo_iconFilled.svg";
import { fetchUserSucceded } from "@/store/slices/user";
import ChangePasswordModal from "@/components/Modals/ChangePasswordModal";
import userService from "@/services/user";

interface IAccountPanel {
  onClose: () => void;
}

export default function AccountPanel({ onClose }: IAccountPanel) {
  const dispatch = useAppDispatch();
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const { userInformation } = useAppSelector((state) => state.user);

  const getProviderIcon = (provider?: string) => {
    if (provider === EProvider.google)
      return (
        <Box sx={styles.alignIcons}>
          <BaseIcon icon={GoogleIcon} /> <Box>Google</Box>
        </Box>
      );
    if (provider === EProvider.apple)
      return (
        <Box sx={styles.alignIcons}>
          <BaseIcon icon={AppleIcon} /> <Box>Apple</Box>
        </Box>
      );

    return (
      <>
        <Mail fontSize="small" /> Email
      </>
    );
  };

  const AccountInfo = [
    {
      id: 1,
      label: "Name",
      info: `${userInformation?.first_name} ${userInformation?.last_name}`,
    },
    {
      id: 1,
      label: "Email",
      info: `${userInformation?.email}`,
    },
    {
      id: 1,
      label: "Created",
      info: `${convertTimeIsoToUs(userInformation?.created_at)}`,
    },
    {
      id: 1,
      label: "Signed in with",
      info: <Box sx={styles.lineWithIcon}>{getProviderIcon(userInformation?.provider)}</Box>,
    },
  ];

  const AccountButtons = [
    {
      id: 1,
      icon: <LockResetOutlinedIcon />,
      label: "Change Password",
      action: () => setOpenChangePasswordModal(true),
      className: "menuListItemPrimary",
      disabled: false,
    },
    {
      id: 2,
      icon: <LogoutOutlined />,
      label: "Sign Out",
      action: () => setOpenAlertDialog(true),
      className: "menuListItemError",
      disabled: false,
    },
    {
      id: 3,
      icon: <DeleteOutline />,
      label: "Delete Account",
      action: () => setOpenDeleteAccountModal(true),
      className: "menuListItemError",
      disabled: true,
    },
  ];

  const callBackLogout = async () => {
    await userService.setLoginHistory(userInformation?.id);
    onClose();
    dispatch(setLoggedUser(false));
    dispatch(fetchUserSucceded(undefined));
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    dispatch(removeUser());
    window.location.reload();
  };

  return (
    <Box>
      <PanelHeader close={onClose} icon={<AdminPanelSettingsOutlined />} title="Account" />
      <List disablePadding sx={styles.scrollContainer}>
        <Box component="li" sx={styles.accountItemsContainer}>
          <List disablePadding sx={styles.accountItems}>
            {AccountInfo.map((item) => (
              <ListItem key={item.label} divider>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    component: "span",
                    variant: "caption",
                    sx: styles.listItemLabel,
                  }}
                  secondary={item.info}
                  secondaryTypographyProps={{
                    component: "span",
                    variant: "body1",
                    sx: styles.listItemInfo,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        {AccountButtons.map((item) => (
          <MenuListItem
            key={item.id}
            action={item.action}
            className={item.className}
            disabled={item.disabled}
            hasArrow={false}
            icon={item.icon}
            name={item.label}
          />
        ))}
      </List>
      {openDeleteAccountModal && (
        <BaseModal
          ariaDescribedby="Delete Account"
          ariaLabelledby="Delete Account"
          open={openDeleteAccountModal}
          title="Delete Account"
          onClose={() => setOpenDeleteAccountModal(false)}
        >
          <DeleteAccountModal onClose={() => setOpenDeleteAccountModal(false)} />
        </BaseModal>
      )}

      {openChangePasswordModal && (
        <BaseModal
          ariaDescribedby="Change Password"
          ariaLabelledby="Change Password"
          open={openChangePasswordModal}
          size="xs"
          title="Change Password"
          onClose={() => setOpenChangePasswordModal(false)}
        >
          <ChangePasswordModal onClose={() => setOpenChangePasswordModal(false)} />
        </BaseModal>
      )}

      <AlertDialog
        close={() => setOpenAlertDialog(false)}
        confirm={callBackLogout}
        message="Are you sure you want to sign out?"
        open={openAlertDialog}
        title="Sign Out"
      />
    </Box>
  );
}
