import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Tabs,
  Tab,
  Drawer,
  IconButton,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  DirectionsBoatFilledOutlined,
  CloseOutlined,
  DeleteOutline,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  EditOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useMap } from "@vis.gl/react-google-maps";

import { styles } from "./styles";

import MainMap from "@/components/ArgoMap/MainMap";
import VoyageInfo from "@/components/VoyageInfo/index";
import BaseModal from "@/components/Modals/BaseModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import CaptainsLogList from "@/components/CaptainsLogList";
import {
  getVoyages,
  getVoyage,
  setSelectedVoyage,
  deleteVoyage,
  checkForMoreItems,
} from "@/store/slices/captainslog/actions";
import { addCourses, setVoyageEditMode, addVoyages } from "@/store/slices/captainslog";
import { IVoyage } from "@/types/route";
import CustomizedSnackbar from "@/components/CustomizedSnackbar";
import { setSnackBarMsj } from "@/store/slices/auth/actions";
import useDisclosure from "@/hooks/useDisclosure";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { debounce } from "@/utils/globals";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as FileExport } from "&/img/icons/FileExportOutlined.svg";
import ActionModal from "@/components/Modals/ActionModal";
import EditRouteModal from "@/components/Modals/EditRouteModal";

export default function CaptainsLog() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const map = useMap();
  const [keyword, setKeyword] = useState<string>("");
  const [sortFilters, setSortFilters] = useState<string>("date_created_desc");
  const [favoritesFilter, setFavoritesFilter] = useState<boolean>(false);
  const [newItems, setNewItems] = useState<boolean>(false);
  const [areMoreItems, setAreMoreItems] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [updatedItem, setUpdatedItem] = useState<boolean>(true);
  const { items, page, setItems, setPage } = useInfiniteScroll();
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [isMapExpanded, setIsMapExpanded] = useState<boolean>(false);
  const [openEditRouteModal, setEditRouteModal] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(true);
  const onToggleMapExpanded = () => setIsMapExpanded(!isMapExpanded);

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
  };

  const dispatch = useAppDispatch();

  const { voyages, selectedVoyage, voyageEditMode, coursesCopy } = useAppSelector(
    (state) => state.captainslog,
  );
  const { snackBarMsj } = useAppSelector((state) => state.auth);

  const handleSnackBarClose = (reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackBarMsj(false, "", ""));
  };

  const handleScrollToTop = () => {
    const element = document.getElementById("scrollableDiv");

    if (element)
      element.scroll({
        top: 0,
        left: 0,
      });
  };

  const handleCheckMoreItems = async (page: number) => {
    const result = await dispatch(
      checkForMoreItems(page + 2, keyword, sortFilters, favoritesFilter),
    );

    setAreMoreItems(result);
  };

  const handleGetVoyages = (page: number) => {
    dispatch(getVoyages(page, keyword, sortFilters, favoritesFilter));
  };

  useEffect(() => {
    handleGetVoyages(1);
  }, []);

  useEffect(() => {
    if ((voyages?.items && voyages?.items?.length >= 0) || deleteItem) {
      if (newItems || deleteItem) {
        setItems(voyages?.items);
        setLoadingItems(false);
        if (deleteItem) setDeleteItem(false);

        if (newItems) {
          setNewItems(false);
          setPage(1);
          handleScrollToTop();
        }

        handleCheckMoreItems(newItems ? 1 : page);
      } else if (updatedItem || voyageEditMode) {
        setItems(() => [...(voyages?.items ?? [])]);
        setLoadingItems(false);
        setPage((prevPage) => prevPage + 1);
        handleCheckMoreItems(page);
        if (updatedItem) setUpdatedItem(false);
      } else {
        setItems((prevItems: IVoyage[]) => [...prevItems, ...(voyages?.items ?? [])]);
        setLoadingItems(false);
        setPage((prevPage) => prevPage + 1);
        handleCheckMoreItems(page);
      }
    }

    return () => {
      if (voyages) {
        dispatch(addVoyages(null));
        dispatch(addCourses(null));
        dispatch(setSelectedVoyage(null));
      }
    };
  }, [voyages]);

  const handleCancelEditRoute = () => {
    dispatch(setSelectedVoyage({ ...selectedVoyage, courses: coursesCopy }));
    dispatch(addCourses(coursesCopy));
    dispatch(setVoyageEditMode(false));
    setEditRouteModal(false);
  };

  const handleCloseWhitoutUpdateCourse = () => {
    dispatch(setVoyageEditMode(false));
    setEditRouteModal(false);
  };

  const handleSelectVoyage = (item: IVoyage | null) => {
    if (!item) {
      dispatch(addCourses(null));
      dispatch(setSelectedVoyage(null));
    } else {
      dispatch(addCourses(null));
      dispatch(getVoyage(item.id, map));
    }
  };

  const handleDeleteRoute = (voyageId: number | undefined) => {
    if (voyageId) {
      setDeleteItem(true);
      dispatch(deleteVoyage({ ...voyages, items }, voyageId));
      if (isMapExpanded) setIsMapExpanded(false);
    }
    onClose();
  };

  const handleSetFavoriteItem = (voyageId: number, favorite: boolean) => {
    const favoritedVoyages = items?.map((item: IVoyage) => {
      if (item.id === voyageId) {
        return {
          ...item,
          favorite: !favorite,
        };
      }

      return item;
    });

    setItems(favoritedVoyages);
    setLoadingItems(false);
  };

  const handleSetFavoritesFilter = (value: boolean) => {
    setFavoritesFilter(value);
  };

  const handleGetItemsByFilters = (filter: string, favorites: boolean) => {
    setNewItems(true);
    dispatch(getVoyages(1, keyword, filter, favorites));
  };

  const handleGetVoyageByKeyword = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewItems(true);
    setKeyword(e.target.value);
    dispatch(getVoyages(1, e.target.value, sortFilters, favoritesFilter));
  }, 500);

  const handleEditRoute = () => {
    setEditRouteModal(true);
    dispatch(setVoyageEditMode(true));
  };

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={{ backgroundColor: "grey.200" }}>
        <Stack alignItems="center" direction="row" ml={1} spacing={5} sx={{ ml: "80px", pl: 2 }}>
          <Typography variant="h6">Captain&apos;s Log</Typography>
          <Tabs aria-label="icon position tabs example" value={0}>
            <Tab icon={<DirectionsBoatFilledOutlined />} iconPosition="start" label="Routes" />
          </Tabs>
        </Stack>
      </Box>
      <Box sx={isMapExpanded ? styles.wrapperExpanded : styles.wrapper}>
        {!isMapExpanded && (
          <Box sx={{ position: "relative", minWidth: "360px" }}>
            {items && (
              <CaptainsLogList
                action={handleSelectVoyage}
                favoritesFilter={favoritesFilter}
                getMoreVoyages={() => handleGetVoyages(page + 1)}
                hasMore={areMoreItems}
                isSelected={selectedVoyage}
                items={items}
                loadingItems={loadingItems}
                setSortFilters={setSortFilters}
                sortFilters={sortFilters}
                voyages={voyages}
                onGetItemsByFilters={handleGetItemsByFilters}
                onGetVoyageByKeyword={handleGetVoyageByKeyword}
                onSetFavoriteItem={handleSetFavoriteItem}
                onSetFavoritesFilter={handleSetFavoritesFilter}
              />
            )}
          </Box>
        )}

        <Box sx={styles.mapContainer}>
          {!!selectedVoyage && (
            <Tooltip arrow placement="top" title={isMapExpanded ? "Collapse Map" : "Expand Map"}>
              <Button
                color="whiteBg"
                size="small"
                sx={styles.expandMapButton}
                variant="iconButtonContained"
                onClick={onToggleMapExpanded}
              >
                {isMapExpanded ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
              </Button>
            </Tooltip>
          )}

          <Box sx={styles.mapContent}>
            <Drawer anchor="left" open={!!selectedVoyage} sx={styles.drawer} variant="persistent">
              <Stack direction="row" justifyContent="space-between" sx={styles.drawerHeader}>
                <Stack spacing={-0.5}>
                  <Typography color="text.secondary" variant="subtitle2">
                    {selectedVoyage?.voyage_type === "route"
                      ? ""
                      : `${dayjs(selectedVoyage?.start_date).format("dddd")} at ${dayjs(
                          selectedVoyage?.start_date,
                        ).format("h:mm A")}`}
                  </Typography>
                  <Typography color="text.primary" lineHeight="normal" variant="h6">
                    {selectedVoyage?.name}
                  </Typography>
                </Stack>
                {!isMapExpanded && (
                  <Stack direction="row" sx={styles.drawerHeaderButtons}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleSelectVoyage(null)}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" p={2}>
                <Button
                  disableElevation
                  startIcon={<BaseIcon icon={FileExport} />}
                  variant="contained"
                  onClick={() => setOpenExportModal(true)}
                >
                  Export to GPX
                </Button>
                <Stack direction="row" spacing={1}>
                  <Tooltip arrow placement="top" title="Delete Route">
                    <IconButton sx={styles.iconButton} onClick={() => onOpen()}>
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Edit Route">
                    <IconButton sx={styles.iconButton} onClick={handleEditRoute}>
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Divider />
              <Box sx={styles.scrollContainer}>
                {selectedVoyage && <VoyageInfo voyage={selectedVoyage} />}
              </Box>
            </Drawer>
            <Box sx={selectedVoyage ? styles.mapContainerOpened : styles.mapContainer}>
              <MainMap />
            </Box>
          </Box>
        </Box>
      </Box>

      <BaseModal
        ariaDescribedby="Delete Route"
        ariaLabelledby="Delete Route"
        open={isOpen}
        size="xs"
        title="Delete Route"
        onClose={onClose}
      >
        <Stack pb={2} pt={1} px={3} spacing={2}>
          <Typography color="text.secondary" variant="body1">
            Are you sure you want to delete this route?
          </Typography>

          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button type="submit" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => handleDeleteRoute(selectedVoyage?.id)}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </BaseModal>
      <CustomizedSnackbar
        close={handleSnackBarClose}
        message={snackBarMsj.msj}
        open={snackBarMsj.state}
        severity={snackBarMsj.type}
      />
      {openExportModal && (
        <ActionModal voyageId={selectedVoyage?.id} onClose={handleCloseExportModal} />
      )}
      {(openEditRouteModal || voyageEditMode) && (
        <BaseModal
          ariaDescribedby="Edit Route"
          ariaLabelledby="Edit Route"
          open={openEditRouteModal || voyageEditMode}
          size="xs"
          title="Edit Route"
          onClose={handleCancelEditRoute}
        >
          <EditRouteModal
            selectedVoyage={selectedVoyage}
            onClose={handleCancelEditRoute}
            onCloseRouteSaved={handleCloseWhitoutUpdateCourse}
            onUpdateItem={setUpdatedItem}
          />
        </BaseModal>
      )}
    </Box>
  );
}
