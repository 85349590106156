import { useRef, useEffect } from "react";
import { Box, Drawer, Grow, Paper, Button, Typography, Stack, Avatar } from "@mui/material";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { DirectionsOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import MapContentBox from "./MapContentBox";
import MainMap from "./MainMap";

import {
  setCongratsModal,
  setQuickPinModal,
  setReportPinSelectedDrawer,
} from "@/store/slices/reportpins";
import { CANCEL_ROUTE_USER_CANCEL_MSG } from "@/utils/route";
import {
  addCreatingRoute,
  resetRouteParams,
  initialState,
  addStartPoint,
  addEndPoint,
} from "@/store/slices/route";
import { styles } from "@/pages/home/styles";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import useSearch from "@/hooks/useSearch";
import { IMapPois } from "@/types/pois";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import SearchBox from "@/components/Search/SearchBox";
import { TPOI_TYPE } from "@/utils/poi";
import { addCategory, setShowSearchArea } from "@/store/slices/search";
import { setDepthShadingDrawerOpen, setDepthShadingToEdit } from "@/store/slices/depthshading";
import useDisclosure from "@/hooks/useDisclosure";
import { setSelectedVoyage } from "@/store/slices/captainslog/actions";
import { addCourses } from "@/store/slices/captainslog";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { KEY_PMODAL_NON_USER, KEY_PMODAL_USER } from "@/utils/keys";
import SelectedPoiDrawer from "@/components/Drawers/SelectedPoiDrawer";
import ReportPinInfo from "@/components/ReportPinInfo";
import BaseModal from "@/components/Modals/BaseModal";
import PinsModal from "@/components/Pins/PinsModal";
import ReportPinCreationInfo from "@/components/Pins/ReportPinCreationInfo";
import QuickPinDrawer from "@/components/Drawers/QuickPinDrawer";
import UserProfileDrawer from "@/components/Drawers/UserProfileDrawer";
import PlaceInfo from "@/components/PlaceInfo";
import PanelHeader from "@/components/PanelHeader";
import BaseDrawer from "@/components/Drawers/BaseDrawer/";
import PoiListDrawer from "@/components/Drawers/PoiListDrawer/PoiListDrawer";
import MyPinsDrawer from "@/components/Drawers/MyPinsDrawer";
import DepthShadingDrawer from "@/components/Drawers/DepthShadingDrawer";
import CongratsPinModal from "@/components/Pins/ReportPinCreationInfo/CongratsPinModal";
import Route from "@/components/Route";
import EditPlacesDrawer from "@/components/Drawers/EditPlacesDrawer";

const searchAreaStyles = {
  position: "absolute",
  top: 68,
  background: "white",
};

function ArgoMap() {
  const captureRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isPremium = usePremiumUser();
  const { getListOfPois, setCleanPoiList, setCleanPoiCategory, setCleanSelectedPoi } = useSearch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPinsModal,
    onClose: onClosePinsModal,
    onOpen: onOpenPinsModal,
  } = useDisclosure();

  /**
   * Redux States
   */
  const { logged, user } = useAppSelector((state) => state.auth);
  const {
    selectedPoi,
    selectedPoiCopy,
    poiList,
    selectedCategories,
    debouncedQuery,
    loading,
    showSearchArea,
  } = useAppSelector((state) => state.search);
  const { voyageEditMode, selectedVoyage, coursesCopy } = useAppSelector(
    (state) => state.captainslog,
  );
  const { reportPinSelectedDrawer, tempPin, congratsModal, myPinsDrawer, quickPinModal } =
    useAppSelector((state) => state.reportpins);
  const { depthShadingDrawerOpen } = useAppSelector((state) => state.depthshading);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { creatingRoute, cancelRouteToken } = useAppSelector((state) => state.route);

  const pmodalKey = logged && user ? KEY_PMODAL_USER + user.email : KEY_PMODAL_NON_USER;
  const pmodalData = localStorage.getItem(pmodalKey);

  useEffect(() => {
    if (voyageEditMode) {
      const routeCreated = selectedVoyage?.courses?.[0];

      if (routeCreated) {
        setCleanPoiList();
        setCleanPoiCategory();
        dispatch(addCreatingRoute(true));

        dispatch(
          addStartPoint({
            lat: routeCreated.start_lat,
            lng: routeCreated.start_lng,
            name: `${routeCreated.start_lat},${routeCreated.start_lng}`,
          }),
        );

        dispatch(
          addEndPoint({
            lat: routeCreated.end_lat,
            lng: routeCreated.end_lng,
            name: `${routeCreated.end_lat},${routeCreated.end_lat}`,
          }),
        );
      }
    }

    return () => {
      dispatch(
        resetRouteParams({
          ...initialState,
        }),
      );
    };
  }, [voyageEditMode]);

  const handleSearchByArea = () => {
    getListOfPois(debouncedQuery, selectedCategories);
  };

  const handleCleanDepthShadingDrawer = () => {
    if (depthShadingDrawerOpen) {
      dispatch(setDepthShadingDrawerOpen(false));
      dispatch(setDepthShadingToEdit(null));
    }
  };

  const handleRouteOpen = (poi?: IMapPois, inputType?: number) => {
    if (logged && isPremium) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));
      // if (coords) dispatch(setCoords(undefined));
      if (poi) {
        if (inputType === 1) {
          dispatch(
            addStartPoint({
              lat: poi.lat,
              lng: poi.lng,
              name: `${poi.name}`,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat: poi.lat,
              lng: poi.lng,
              name: `${poi.name}`,
            }),
          );
        }
        setCleanSelectedPoi();
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  const handleCloseRoute = () => {
    cancelRouteToken?.cancel(CANCEL_ROUTE_USER_CANCEL_MSG);
    setCleanPoiList();
    dispatch(addCategory([]));
    dispatch(
      resetRouteParams({
        ...initialState,
      }),
    );
    if (showSearchArea) dispatch(setShowSearchArea(false));
  };

  const handleOpenEditRouteModal = () => {
    onOpen();
  };

  const handleCloseEditRoute = () => {
    dispatch(setSelectedVoyage({ ...selectedVoyage, courses: coursesCopy }));
    dispatch(addCourses(coursesCopy));
    navigate("/captains-log");
    handleCloseRoute();
  };

  const handleSetCategories = (categoriesToSet: TPOI_TYPE[]) => {
    if (categoriesToSet.length !== 0 || debouncedQuery !== "") {
      getListOfPois(debouncedQuery, categoriesToSet);
    } else if (debouncedQuery === "") {
      setCleanPoiList();
    }
  };

  const handleClearAll = (selectedCategories: TPOI_TYPE[], selectedItemsToDelete: TPOI_TYPE[]) => {
    const valoresSet = new Set(selectedItemsToDelete.map((obj) => obj.value));

    const resultado = selectedCategories.filter((obj) => !valoresSet.has(obj.value));

    return resultado;
  };

  const handleSearchCategory = (
    category: TPOI_TYPE | undefined,
    action: boolean,
    clearAll?: any,
  ) => {
    handleCleanDepthShadingDrawer();
    if (clearAll && category === undefined) {
      const selectedCategoriesFiltered = handleClearAll(selectedCategories, clearAll.checkedItems);

      dispatch(addCategory(selectedCategoriesFiltered));
      handleSetCategories(selectedCategoriesFiltered);
    } else if (!action && category !== undefined) {
      const selectedCategoriesFiltered = selectedCategories.filter(
        (selectedCategory: TPOI_TYPE) => selectedCategory.value !== category.value,
      );

      dispatch(addCategory(selectedCategoriesFiltered));
      handleSetCategories(selectedCategoriesFiltered);
    } else if (category !== undefined) {
      const categoriesToSet = [...selectedCategories, category];

      dispatch(addCategory(categoriesToSet));
      handleSetCategories(categoriesToSet);
    }
  };

  const handleDeleteCategory = (value: TPOI_TYPE) => {
    handleCleanDepthShadingDrawer();
    const categoriesToSet = selectedCategories.filter(
      (selectedCategories: TPOI_TYPE) =>
        !(selectedCategories.type === value.type && selectedCategories.value === value.value),
    );

    dispatch(addCategory(categoriesToSet));
    handleSetCategories(categoriesToSet);
  };

  const handleCloseSelectedPoi = () => {
    setCleanSelectedPoi();
  };

  const handleCloseReportPin = () => {
    dispatch(setReportPinSelectedDrawer(null));
  };

  const handleCloseProfileDrawer = () => {
    if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
  };

  const handleSetDonShowAgain = () => {
    dispatch(setQuickPinModal(false));
    localStorage.setItem(pmodalKey, "true");
  };

  const handleTakeScreenshot = async (): Promise<File | null> => {
    const element = captureRef.current;

    if (element) {
      const canvas = await html2canvas(element, { useCORS: true });

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `screenshot-${dayjs().unix()}.png`, {
              type: "image/png",
            });

            resolve(file);
          } else {
            resolve(null);
          }
        }, "image/png");
      });
    }

    return null;
  };

  const openDrawerPoi = selectedPoi && !poiList && !selectedPoi?.editMode;
  const openDrawerPoiList = !creatingRoute && (loading || poiList);

  return (
    <>
      <MapContentBox
        ref={captureRef}
        expand={
          creatingRoute ||
          !!openDrawerPoi ||
          !!openDrawerPoiList ||
          (!!reportPinSelectedDrawer && !myPinsDrawer?.open) ||
          !!myPinsDrawer?.open
        }
        sx={styles.mapContent}
      >
        <MainMap />
      </MapContentBox>
      <Box sx={styles.searchBox}>
        <SearchBox
          action={handleRouteOpen}
          onDeleteCategory={handleDeleteCategory}
          onSearchCategory={handleSearchCategory}
        />
      </Box>
      <Box sx={{ position: "absolute", zIndex: 7, width: "100%" }}>
        {showSearchArea && (
          <Button
            color="inherit"
            startIcon={<SearchIcon />}
            sx={{
              ...searchAreaStyles,
              left: selectedPoi ? "calc(50% + 152px)" : "calc(50% + 152px)",
            }}
            type="button"
            variant="contained"
            onClick={handleSearchByArea}
          >
            Search this Area
          </Button>
        )}
      </Box>
      {/* GENERAL DRAWER */}
      <BaseDrawer open={!!openDrawerPoi} styles={styles.drawer}>
        {selectedPoi && (
          <SelectedPoiDrawer selectedPoi={selectedPoi} onCreateOpenRoute={handleRouteOpen} />
        )}
      </BaseDrawer>
      {/* REPORT PIN DRAWER */}
      <BaseDrawer open={!!reportPinSelectedDrawer && !myPinsDrawer?.open} styles={styles.drawer}>
        {!!reportPinSelectedDrawer && !myPinsDrawer?.open && (
          <Box sx={styles.scrollContainer}>
            <ReportPinInfo isDrawer />
          </Box>
        )}
      </BaseDrawer>
      <BaseDrawer open={!!openDrawerPoiList} styles={styles.drawer}>
        <PoiListDrawer categorySelected={Boolean(selectedCategories.length)} />
        <Stack alignItems="center" direction="row" justifyContent="center" p={2}>
          <Typography variant="body2">Don&apos;t see what you&apos;re looking for? </Typography>
          <Button color="secondary" sx={{ ml: 1 }} onClick={onOpenPinsModal}>
            Add a Place
          </Button>
          {isOpenPinsModal && (
            <PinsModal
              isOpen={isOpenPinsModal}
              pinsType="places"
              title="Add a Place"
              onClose={onClosePinsModal}
            />
          )}
        </Stack>
      </BaseDrawer>
      {/* DRAWER POI IF EXIST POI LIST OR CREATING ROUTE */}``
      {((poiList && selectedPoi && !selectedPoi?.editMode) ||
        (myPinsDrawer?.open && selectedPoi) ||
        (myPinsDrawer?.open && reportPinSelectedDrawer)) && (
        <Grow
          unmountOnExit
          in={
            !!(
              (poiList && selectedPoi && !selectedPoi?.editMode) ||
              (myPinsDrawer?.open && selectedPoi) ||
              (myPinsDrawer?.open && reportPinSelectedDrawer)
            )
          }
          style={{ transformOrigin: "top left" }}
          timeout="auto"
        >
          <Paper elevation={3} sx={styles.placePanel}>
            <Box sx={styles.scrollContainer}>
              {reportPinSelectedDrawer ? (
                <ReportPinInfo close={handleCloseReportPin} />
              ) : !selectedPoi?.place_type && !selectedPoi?.poi_type ? (
                <QuickPinDrawer close={handleCloseSelectedPoi} poiSelected={selectedPoi ?? {}} />
              ) : (
                <PlaceInfo
                  close={handleCloseSelectedPoi}
                  poiSelected={selectedPoi ?? {}}
                  onCreateOpenRoute={handleRouteOpen}
                />
              )}
            </Box>
          </Paper>
        </Grow>
      )}
      {/* DRAWER CREATE ROUTE */}
      <Drawer
        anchor="left"
        open={creatingRoute}
        sx={{ ...styles.drawer, zIndex: 8 }}
        variant="persistent"
      >
        {creatingRoute && (
          <>
            <PanelHeader
              close={voyageEditMode ? handleOpenEditRouteModal : handleCloseRoute}
              editRouteMode={voyageEditMode}
              icon={<DirectionsOutlined />}
              title={voyageEditMode ? "Edit Route" : "Create Route"}
            />

            <Box id="scrollableDivRoute">
              <Route
                onDeleteCategory={handleDeleteCategory}
                onSearchCategory={handleSearchCategory}
                onTakeScreenshot={handleTakeScreenshot}
              />
            </Box>
          </>
        )}
      </Drawer>
      {/* MY PINS DRAWER */}
      {myPinsDrawer?.open && (
        <BaseDrawer open={myPinsDrawer?.open} styles={styles.drawer}>
          <Box sx={styles.scrollContainer}>
            <MyPinsDrawer />
          </Box>
        </BaseDrawer>
      )}
      {/* BOATER DRAWER */}
      {!!boaterSelectedInfoDrawer && (
        <BaseDrawer open={!!boaterSelectedInfoDrawer} styles={styles.drawer}>
          <Box sx={styles.scrollContainer}>
            <UserProfileDrawer user={boaterSelectedInfoDrawer} onClose={handleCloseProfileDrawer} />
          </Box>
        </BaseDrawer>
      )}
      {/* REPORT PIN CREATION DRAWER */}
      {!!tempPin && <ReportPinCreationInfo open={!!tempPin} />}
      {/* DEPTH SHADING DRAWER */}
      {depthShadingDrawerOpen && <DepthShadingDrawer open={depthShadingDrawerOpen} />}
      {selectedPoi?.editMode && (
        <EditPlacesDrawer
          open={selectedPoi?.editMode}
          selectedPoi={selectedPoi}
          selectedPoiCopy={selectedPoiCopy}
        />
      )}
      {congratsModal?.open && (
        <BaseModal
          ariaDescribedby="Thank you"
          ariaLabelledby="Thank you"
          open={congratsModal?.open}
          size="xs"
          title=""
          onClose={() => dispatch(setCongratsModal({ open: false, type: "places" }))}
        >
          <CongratsPinModal
            onClose={() => dispatch(setCongratsModal({ open: false, type: "places" }))}
          />
        </BaseModal>
      )}
      {quickPinModal && !pmodalData && (
        <BaseModal
          ariaDescribedby="New Pin"
          ariaLabelledby="New Pin"
          icon={
            <Avatar
              alt="quickpin"
              src="/img/markers/quickpin/3x/QuickPin.png"
              sx={{ width: 24, height: 24 }}
            />
          }
          open={quickPinModal && !pmodalData}
          size="xs"
          title="New Pin"
          onClose={() => {
            dispatch(setQuickPinModal(false));
          }}
        >
          <Stack pb={2} pt={1} px={3} spacing={2}>
            <Typography color="text.primary" variant="body1">
              You dropped a new pin. Tap on it to select a pin type and add more information.
            </Typography>

            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button type="submit" onClick={() => handleSetDonShowAgain()}>
                Don’t Show Again
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  dispatch(setQuickPinModal(false));
                }}
              >
                Dismiss
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      )}
      <BaseModal
        ariaDescribedby="Discard Changes"
        ariaLabelledby="Discard Changes"
        open={isOpen}
        title="Discard Changes"
        onClose={onClose}
      >
        <Stack>
          <Typography px={3}>
            By leaving, you will lose all changes you&apos;ve made to this route.
          </Typography>
          <Stack direction="row" justifyContent="flex-end" p={3} spacing={2}>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button type="button" variant="contained" onClick={handleCloseEditRoute}>
              Discard
            </Button>
          </Stack>
        </Stack>
      </BaseModal>
    </>
  );
}

export default ArgoMap;
