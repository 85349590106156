import React, { useMemo } from "react";

import AdvancedMarker from "../AdvancedMarker";
import PlacePopover from "../../MapPopovers/PlacePopover/PlacePopover";
import { getPoiIcon } from "../Utils/PoiIcons";
// import PoiMarker from "./PoiMarker";
// import BridgeMapMarker from "./BridgeMapMarker";

import BridgeMapIcon from "./BridgeMapIcon";

import { addStartPoint, addEndPoint } from "@/store/slices/route";
import ServicePoi from "@/services/poi";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { addSelectedPoi, addSelectedPoiFromMap } from "@/store/slices/search";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { IMapPois } from "@/types/pois";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { IconKeysPois } from "@/types/markers";

interface IPoisList {
  items: IMapPois[];
  zoom?: number;
  clogpage: boolean;
}

interface IPoisStatus {
  [key: string]: boolean;
}

const ZOOM_LEVEL = 10;

const ZOOM_LEVEL_REST_BAR = 15;
const ZOOM_LEVEL_NOOA_RESTRICTION = 16;

const KEY_REST_BAR = "RestaurantBar";
const KEY_BUSINESS = "Business";
const KEY_HOTEL = "Hotel";

function PoisList({ items, zoom, clogpage }: IPoisList) {
  const dispatch = useAppDispatch();
  const { poisConfigured } = useAppSelector((state) => state.map);
  const { creatingRoute, startPoint } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const { selectedPoi } = useAppSelector((state) => state.search);
  const { logged } = useAppSelector((state) => state.auth);

  const poisStatus: IPoisStatus = {
    Marina: poisConfigured.Marina,
    BoatRamp: poisConfigured.BoatRamp,
    Anchorage: poisConfigured.Anchorage,
    YachtClub: poisConfigured["Yacht Club"],
    BoatClub: poisConfigured["Boat Club"],
    RestaurantBar: poisConfigured["Restaurant/Bar"],
    ArtificialReef: poisConfigured.ArtificialReef,
    ClubAssociation: poisConfigured["Club/Association"],
    Dam: poisConfigured.Dam,
    Lock: poisConfigured.Lock,
    Bridge: poisConfigured.Bridge,
    Business: poisConfigured.Business,
    CityTown: poisConfigured.CityTown,
    Hotel: poisConfigured.Hotel,
    Inlet: poisConfigured.Inlet,
    Other: poisConfigured.Other,
    Beach: poisConfigured.Beach,
    FreeDock: poisConfigured["Free Dock"],
    Mooring: poisConfigured.Mooring,
    DockBarRestaurant: poisConfigured["Dock Bar/Restaurant"],
    FishingSpot: poisConfigured["Fishing Spot"],
    LightHouse: poisConfigured["Light House"],
    PointofInterest: poisConfigured["Point of Interest"],
    PrivateDock: poisConfigured["Private Dock"],
  };

  /**
   * This function checks the zoom level to show the pois
   * @param key Type string
   * @returns boolean
   */
  const zoomChecks = (key: string) => {
    if (poisConfigured.noaaLayer) {
      if (zoom && zoom > ZOOM_LEVEL) {
        if (zoom && zoom >= ZOOM_LEVEL_NOOA_RESTRICTION) {
          return true;
        }
      }

      return false;
    }

    if (key === KEY_REST_BAR || key === KEY_BUSINESS || key === KEY_HOTEL) {
      if (zoom && zoom >= ZOOM_LEVEL_REST_BAR) {
        return true;
      }

      return false;
    }

    if (zoom && zoom > ZOOM_LEVEL) {
      return true;
    }

    return false;
  };

  /**
   * This function get the poi information by id
   * @param item Type IMapPois
   */
  const handleGetPoiInfo = async (item: IMapPois) => {
    if (logged) {
      try {
        const { status, data } = await ServicePoi.getPoiById(item.id);

        if (status === 200) {
          if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
          if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
          dispatch(addSelectedPoi(data.data));
          dispatch(addSelectedPoiFromMap(false));
          const { status: statusRaiting, data: dataRaiting } = await ServicePoi.getPoiRatings(
            data?.data?.id,
          );

          if (statusRaiting === 200) {
            dispatch(addSelectedPoi({ ...data.data, all_reviews: dataRaiting }));
          }
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  /**
   * This function set the selected poi for the route or the drawer to show extra information
   * @param item Type IMapPois
   */
  const handleSetPoi = (item: IMapPois) => {
    if (logged && !clogpage) {
      if (!creatingRoute && item) {
        handleGetPoiInfo(item);
      } else if (creatingRoute) {
        const lat = Number(item.latitude);
        const lng = Number(item.longitude);

        if (!startPoint) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        }
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  // const getStylesIconCluster = (type: string) => ({
  //   url: `./img/markers/clusters/3x/${type}.png`,
  //   width: 40,
  //   height: 40,
  //   textSize: 11,
  //   backgroundPosition: "center center",
  // });

  // const filterMarkersByType = (items: IMapPois[], type: string) =>
  //   items.filter((item) => item.poi_type.replace("/", "").replace(" ", "") === type);

  // const renderMarkers = useCallback(
  //   (markers: IMapPois[], clusterer: any) =>
  //     markers.map((marker: IMapPois) => {
  //       const key = marker.poi_type.replace("/", "").replace(" ", "");

  //       if (!zoomChecks(key) || !poisStatus[key]) return null;

  //       const imageURL = "pois/place/3x/";
  //       const poiUID = selectedPoi && selectedPoi?.poi_type ? selectedPoi?.id : selectedPoi?.id;

  //       const markerUID = selectedPoi && selectedPoi?.poi_type ? marker?.id : marker?.id;
  //       const size = new window.google.maps.Size(36, 36);
  //       const selectedSize = new window.google.maps.Size(48, 74);

  //       const iconBridgeURL =
  //         marker?.poi_type === "Bridge"
  //           ? marker?.info?.ver_clr !== null &&
  //             marker?.info?.ver_clr !== undefined &&
  //             marker?.info?.ver_clr !== "" &&
  //             marker?.info?.ver_clr !== "0" &&
  //             marker?.info?.ver_clr !== "0.0"
  //             ? "BridgeClearance"
  //             : "Bridge"
  //           : null;
  //       const selectedURL = "selected/";
  //       const iconSelectedURL = `${imageURL}${selectedURL}${marker.poi_type
  //         .replace("/", "")
  //         .replace(" ", "")}`;

  //       if (marker?.poi_type === "Bridge")
  //         return (
  //           <BridgeMapMarker
  //             key={marker.id}
  //             icon={`${imageURL}${iconBridgeURL}`}
  //             marker={marker}
  //             onGetPoiInfo={handleGetPoiInfo}
  //           />
  //         );

  //       return (
  //         <Box key={marker.id}>
  //           {selectedPoi && poiUID === markerUID ? (
  //             <PoiMarker
  //               key={marker.id}
  //               clusterer={clusterer}
  //               icon={`${iconSelectedURL}.png`}
  //               poi={marker}
  //               position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
  //               size={selectedSize}
  //               onClick={() => handleSetPoi(marker)}
  //             />
  //           ) : (
  //             <PoiMarker
  //               key={marker.id}
  //               clusterer={clusterer}
  //               icon={`${imageURL}${marker.poi_type.replace("/", "").replace(" ", "")}.png`}
  //               poi={marker}
  //               position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
  //               size={size}
  //               onClick={() => handleSetPoi(marker)}
  //             />
  //           )}
  //         </Box>
  //       );
  //     }),
  //   [items, poisStatus],
  // );

  // const getMarkerClusterers = useMemo(() => {
  //   const uniqueTypes = Array.from(
  //     new Set(items.map((item) => item.poi_type.replace("/", "").replace(" ", ""))),
  //   );

  //   return uniqueTypes.map((type) => (
  //     <MarkerClusterer
  //       key={type}
  //       options={{
  //         maxZoom: 14,
  //         styles: [getStylesIconCluster(type)],
  //         minimumClusterSize: 4,
  //       }}
  //     >
  //       {(clusterer) => <Box>{renderMarkers(filterMarkersByType(items, type), clusterer)}</Box>}
  //     </MarkerClusterer>
  //   ));
  // }, [items, renderMarkers]);

  /**
   * This function evaluate if the icon bridge has clearance or not.
   * @param poi IMapPois
   * @returns React.ReactNode or String
   */
  const evaluateIcons = (poi: IMapPois) => {
    const iconBridgeType =
      poi?.poi_type === "Bridge"
        ? poi?.info?.ver_clr !== null &&
          poi?.info?.ver_clr !== undefined &&
          poi?.info?.ver_clr !== "" &&
          poi?.info?.ver_clr !== "0" &&
          poi?.info?.ver_clr !== "0.0"
          ? IconKeysPois.BridgeClearance
          : IconKeysPois.Bridge
        : null;

    if (iconBridgeType === IconKeysPois.BridgeClearance) {
      return <BridgeMapIcon infoIcon={poi} />;
    }

    return getPoiIcon(poi.poi_type as IconKeysPois);
  };

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const key = item.poi_type.replace("/", "").replace(" ", "");

        return zoomChecks(key) && poisStatus[key];
      }),
    [items, zoom, poisStatus, poisConfigured],
  );

  const itemsToRender = selectedPoi?.editMode
    ? filteredItems.filter((poi: IMapPois) => selectedPoi?.id !== poi.id)
    : filteredItems;

  return (
    <>
      {itemsToRender.map((poi: IMapPois) => (
        <AdvancedMarker
          key={poi.id}
          icon={evaluateIcons(poi)}
          position={{ lat: Number(poi.latitude), lng: Number(poi.longitude) }}
          selected={selectedPoi?.id === poi.id}
          onClick={() => handleSetPoi(poi)}
        >
          {logged && (
            <PlacePopover
              icon={evaluateIcons(poi)}
              inCaptainsLog={clogpage}
              poi={poi}
              onClick={() => null}
            />
          )}
        </AdvancedMarker>
      ))}
    </>
  );
}

export default PoisList;
