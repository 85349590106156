import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import { useUnitSystem } from "@argonav/unit-system";
import { SelectChangeEvent } from "@mui/material/Select";

import PlaceCoordinatePanel from "./PlaceCoordinatesPanel";
import { TPlace } from "./EditPlacesDrawer";
import { styles } from "./styles";

import Dropzone from "@/components/Dropzone";
import { IMapPois } from "@/types/pois";
import { useAppDispatch } from "@/hooks/useRedux";
import { addSelectedPoiCopy } from "@/store/slices/search";

const KEY_PLACE_MARINA = "Marina";
const KEY_PLACE_BRIDGE = "Bridge";
const KEY_PLACE_LOCK = "Lock";

const bridgeTypes = [
  { label: "Fixed", value: "Fixed" },
  { label: "Moveable", value: "Moveable" },
];

interface ICreatePinForm {
  editCoordinates: boolean;
  placeType: string;
  formData: FormikProps<TPlace>;
  selectedPoiCopy: IMapPois | null;
  selectedPoi: IMapPois | null;
  onEditCoordinates: (value: boolean) => void;
}

const BYTE_SIZE_LIMIT = 2e6;

function EditPlacesForm({
  formData,
  placeType,
  editCoordinates,
  selectedPoi,
  selectedPoiCopy,
  onEditCoordinates,
}: ICreatePinForm) {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>(formData.values.assets_attributes || []);
  const { coordinatesFormatHandler } = useUnitSystem();

  const handleBoatImage = (uploadedFiles: File[] | any[]) => {
    setFiles([...uploadedFiles]);

    if (
      Array.from(uploadedFiles).some((file) => file.size <= BYTE_SIZE_LIMIT) ||
      Array.from(uploadedFiles).some((file) => file.id)
    ) {
      formData.setFieldValue("assets_attributes", Array.from(uploadedFiles));
    } else {
      setFiles([]); // Limpiar estado si no hay archivos válidos
      formData.setFieldValue("assets_attributes", null);
    }
  };

  const handleSetCoords = () => {
    onEditCoordinates(true);
    dispatch(addSelectedPoiCopy({ ...selectedPoi, editMode: true }));
  };

  const handleClose = () => {
    dispatch(addSelectedPoiCopy(null));
    onEditCoordinates(false);
  };

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(formData.values.location.lat),
    longitude: Number(formData.values.location.lng),
  });

  const latLngFormattedCoordinates = `${Number(selectedPoiCopy?.lat)}, ${Number(
    formData.values.location.lng,
  )}`;

  const marinaType = placeType === KEY_PLACE_MARINA;
  const bridgeType = placeType === KEY_PLACE_BRIDGE;
  const lockType = placeType === KEY_PLACE_LOCK;

  return (
    <Stack spacing={2} sx={styles.formContainer}>
      <FormControl>
        <InputLabel htmlFor="name">Name</InputLabel>
        <OutlinedInput
          defaultValue={formData.values.name}
          error={formData.touched.name && Boolean(formData.errors.name)}
          id="name"
          label="Name"
          onChange={formData.handleChange}
        />
        <FormHelperText id="name-text" sx={{ color: "error.main" }}>
          {formData.touched.name && formData.errors.name}
        </FormHelperText>
      </FormControl>
      {marinaType && (
        <FormControl>
          <InputLabel htmlFor="description">Description</InputLabel>
          <OutlinedInput
            multiline
            defaultValue={formData.values.description}
            error={formData.touched.description && Boolean(formData.errors.description)}
            id="description"
            label="Description"
            minRows={3}
            onChange={formData.handleChange}
          />
          <FormHelperText id="name-text" sx={{ color: "error.main" }}>
            {formData.touched.description && formData.errors.description}
          </FormHelperText>
        </FormControl>
      )}
      <FormControl>
        <InputLabel htmlFor="location">Location</InputLabel>
        <OutlinedInput
          disabled
          endAdornment={
            <Box>
              <Button
                color="primary"
                disabled={editCoordinates}
                size="small"
                sx={{ fontSize: "13px", width: "128px" }}
                variant="outlined"
                onClick={handleSetCoords}
              >
                Edit Coordinates
              </Button>
            </Box>
          }
          error={formData.touched.location && Boolean(formData.errors.location)}
          id="location"
          label="Location"
          sx={editCoordinates ? null : styles.locationInput}
          value={latLngFormatted}
        />
        <FormHelperText id="name-text">Click on the map to change location</FormHelperText>
        {editCoordinates && (
          <PlaceCoordinatePanel
            coordinates={latLngFormattedCoordinates}
            formData={formData}
            isOpen={editCoordinates}
            onClose={handleClose}
          />
        )}
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="address">Address</InputLabel>
        <OutlinedInput
          defaultValue={formData.values.address}
          error={formData.touched.address && Boolean(formData.errors.address)}
          id="address"
          label="address"
          onChange={formData.handleChange}
        />
        <FormHelperText id="name-text" sx={{ color: "error.main" }}>
          {formData.touched.address && formData.errors.address}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="phone">Phone</InputLabel>
        <OutlinedInput
          defaultValue={formData.values.phone}
          error={formData.touched.phone && Boolean(formData.errors.phone)}
          id="phone"
          label="phone"
          onChange={formData.handleChange}
        />
        <FormHelperText id="name-text" sx={{ color: "error.main" }}>
          {formData.touched.phone && formData.errors.phone}
        </FormHelperText>
      </FormControl>
      {bridgeType && (
        <>
          <FormControl>
            <InputLabel htmlFor="bridge_type">Bridge Type</InputLabel>
            <Select
              id="bridge_type"
              label="bridge_type"
              labelId="bridge_type"
              value={formData.values.bridge_type}
              onChange={(e: SelectChangeEvent) =>
                formData.setFieldValue("bridge_type", e.target.value as string)
              }
            >
              {bridgeTypes.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              Clearance
            </Typography>

            <Stack direction="row" spacing={2}>
              <FormControl>
                <InputLabel htmlFor="ver_clr">Vertical</InputLabel>
                <OutlinedInput
                  defaultValue={formData.values.ver_clr}
                  error={formData.touched.ver_clr && Boolean(formData.errors.ver_clr)}
                  id="ver_clr"
                  label="ver_clr"
                  onChange={formData.handleChange}
                />
                <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                  {formData.touched.ver_clr && formData.errors.ver_clr}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="open_ver_clr">Open Vertical</InputLabel>
                <OutlinedInput
                  defaultValue={formData.values.open_ver_clr}
                  error={formData.touched.open_ver_clr && Boolean(formData.errors.open_ver_clr)}
                  id="open_ver_clr"
                  label="open_ver_clr"
                  onChange={formData.handleChange}
                />
                <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                  {formData.touched.open_ver_clr && formData.errors.open_ver_clr}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="open_hor_clr">Horizontal</InputLabel>
                <OutlinedInput
                  defaultValue={formData.values.open_hor_clr}
                  error={formData.touched.open_hor_clr && Boolean(formData.errors.open_hor_clr)}
                  id="open_hor_clr"
                  label="open_hor_clr"
                  onChange={formData.handleChange}
                />
                <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                  {formData.touched.open_hor_clr && formData.errors.open_hor_clr}
                </FormHelperText>
              </FormControl>
            </Stack>
          </Box>
        </>
      )}
      {lockType && (
        <>
          <Typography sx={{ mb: 0 }} variant="subtitle1">
            Chambers
          </Typography>
          <FormControl>
            <InputLabel htmlFor="number_of_chamber">Number of Chambers</InputLabel>
            <OutlinedInput
              defaultValue={formData.values.number_of_chamber}
              error={
                formData.touched.number_of_chamber && Boolean(formData.errors.number_of_chamber)
              }
              id="number_of_chamber"
              label="number_of_chamber"
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.number_of_chamber && formData.errors.number_of_chamber}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="lock_size">Size</InputLabel>
            <OutlinedInput
              multiline
              defaultValue={formData.values.lock_size}
              error={formData.touched.lock_size && Boolean(formData.errors.lock_size)}
              id="lock_size"
              label="lock_size"
              minRows={2}
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.lock_size && formData.errors.lock_size}
            </FormHelperText>
          </FormControl>
        </>
      )}
      {marinaType && (
        <>
          <FormControl>
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <OutlinedInput
              defaultValue={formData.values.email}
              error={formData.touched.email && Boolean(formData.errors.email)}
              id="email"
              label="email"
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.email && formData.errors.email}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="website">Website</InputLabel>
            <OutlinedInput
              defaultValue={formData.values.website}
              error={formData.touched.website && Boolean(formData.errors.website)}
              id="website"
              label="website"
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.website && formData.errors.website}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="number_of_slips">Number of Slips</InputLabel>
            <OutlinedInput
              defaultValue={formData.values.number_of_slips}
              error={formData.touched.number_of_slips && Boolean(formData.errors.number_of_slips)}
              id="number_of_slips"
              label="number_of_slips"
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.number_of_slips && formData.errors.number_of_slips}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={formData.values.clean_marina}
                  onChange={() =>
                    formData.setFieldValue("clean_marina", !formData.values.clean_marina)
                  }
                />
              }
              label="Certified Clean Marina"
            />
          </FormControl>
        </>
      )}
      {bridgeType ||
        (lockType && (
          <>
            <FormControl>
              <InputLabel htmlFor="vhf_channel">VHF Channel</InputLabel>
              <OutlinedInput
                defaultValue={formData.values.vhf_channel}
                error={formData.touched.vhf_channel && Boolean(formData.errors.vhf_channel)}
                id="vhf_channel"
                label="vhf_channel"
                onChange={formData.handleChange}
              />
              <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                {formData.touched.vhf_channel && formData.errors.vhf_channel}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="after_hours_contact">After hours</InputLabel>
              <OutlinedInput
                multiline
                defaultValue={formData.values.after_hours_contact}
                error={
                  formData.touched.after_hours_contact &&
                  Boolean(formData.errors.after_hours_contact)
                }
                id="after_hours_contact"
                label="after_hours_contact"
                minRows={3}
                onChange={formData.handleChange}
              />
              <FormHelperText id="name-text" sx={{ color: "error.main" }}>
                {formData.touched.after_hours_contact && formData.errors.after_hours_contact}
              </FormHelperText>
            </FormControl>
          </>
        ))}
      {bridgeType && (
        <>
          <FormControl>
            <InputLabel htmlFor="bridge_schedule">Schedule info</InputLabel>
            <OutlinedInput
              multiline
              defaultValue={formData.values.bridge_schedule}
              error={formData.touched.bridge_schedule && Boolean(formData.errors.bridge_schedule)}
              id="bridge_schedule"
              label="bridge_schedule"
              minRows={3}
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.bridge_schedule && formData.errors.bridge_schedule}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="bridge_other_info">Other info</InputLabel>
            <OutlinedInput
              multiline
              defaultValue={formData.values.bridge_other_info}
              error={
                formData.touched.bridge_other_info && Boolean(formData.errors.bridge_other_info)
              }
              id="bridge_other_info"
              label="bridge_other_info"
              minRows={3}
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.bridge_other_info && formData.errors.bridge_other_info}
            </FormHelperText>
          </FormControl>
        </>
      )}
      {lockType && (
        <>
          <FormControl>
            <InputLabel htmlFor="lock_schedule">Schedule info</InputLabel>
            <OutlinedInput
              multiline
              defaultValue={formData.values.lock_schedule}
              error={formData.touched.lock_schedule && Boolean(formData.errors.lock_schedule)}
              id="lock_schedule"
              label="lock_schedule"
              minRows={3}
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.lock_schedule && formData.errors.lock_schedule}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="lock_other_info">Other info</InputLabel>
            <OutlinedInput
              multiline
              defaultValue={formData.values.lock_other_info}
              error={formData.touched.lock_other_info && Boolean(formData.errors.lock_other_info)}
              id="lock_other_info"
              label="lock_other_info"
              minRows={3}
              onChange={formData.handleChange}
            />
            <FormHelperText id="name-text" sx={{ color: "error.main" }}>
              {formData.touched.lock_other_info && formData.errors.lock_other_info}
            </FormHelperText>
          </FormControl>
        </>
      )}

      <Dropzone multiple action={handleBoatImage} files={files} />
    </Stack>
  );
}

export default EditPlacesForm;
