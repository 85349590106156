import React, { useEffect, useState } from "react";
import { List, Typography, Avatar, Stack } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  CategoryOutlined,
  ExpandMoreOutlined,
  FavoriteBorderOutlined,
  History,
  HomeOutlined,
  MyLocation,
} from "@mui/icons-material";
import dayjs from "dayjs";

import SearchCoordinatesExpanded from "../SearchCoordinatesExpanded";
import SearchResultListItem from "../SearchResultListItem/SearchResultListItem";
import SearchCategories from "../SearchCategories";

import { DEFAULT_ZOOM } from "@/utils/maps";
import { addSelectedPoi } from "@/store/slices/search";
import { addZoom, addCenter } from "@/store/slices/map";
import {
  addPointSearchedByCoords,
  addStartPoint,
  addEndPoint,
  setRoutePointSetted,
} from "@/store/slices/route";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getFavorites, getSearchHistory, getVessels } from "@/store/slices/user/actions";
import { IMapPois } from "@/types/pois";
import { findPoi, TPOI_TYPE } from "@/utils/poi";
import AlertDialog from "@/components/AlertDialog";
import useSearch from "@/hooks/useSearch";
import EmptyState from "@/components/EmptyState/EmptyState";
import { RoutePosition } from "@/types/map";
import { setDepthShadingDrawerOpen, setDepthShadingToEdit } from "@/store/slices/depthshading";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  boxShadow: "inset 0 1px 10px rgb(0 0 0 / 5%)",
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlined />} {...props} />
))(({ theme }) => ({
  "&.MuiButtonBase-root:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
    "& .MuiAvatar-root": {
      color: theme.palette.primary.main,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    },
  },
  "& .MuiAccordionSummary-content": {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
    alignItems: "center",
  },
  "&.MuiButtonBase-root.Mui-expanded": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    color: theme.palette.primary.main,
    "& .MuiAvatar-root": {
      color: theme.palette.primary.main,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.12)}`,
    },
  },

  "& .MuiAvatar-root": {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1.5),
    width: "32px",
    height: "32px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
}));

interface ISearchAccordion {
  inputType?: number;
  selectedCategories: TPOI_TYPE[];
  setPoiToRoute?: (poi: IMapPois | RoutePosition, inputType: number) => void;
  onSetInputValue: (value: TPOI_TYPE | undefined, action: boolean, clearAll?: any) => void;
  onCloseAccordion?: () => void;
}

export default function SearchAccordion({
  inputType,
  selectedCategories,
  setPoiToRoute,
  onSetInputValue,
  onCloseAccordion,
}: ISearchAccordion) {
  const dispatch = useAppDispatch();
  const { setPoi } = useSearch();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { logged } = useAppSelector((state) => state.auth);
  const { searchHistory, favorites, userInformation } = useAppSelector((state) => state.user);
  const { depthShadingDrawerOpen } = useAppSelector((state) => state.depthshading);
  const { creatingRoute, inputsFocus } = useAppSelector((state) => state.route);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertDialogMessage, setAlertDialogMessage] = useState<string>("");
  const { pointSearchedByCoords } = useAppSelector((state) => state.route);

  /**
   * When we detect the login state we dispatch
   * these API calls to retrieve the user search stuff
   */
  useEffect(() => {
    if (logged) {
      dispatch(getSearchHistory());
      dispatch(getFavorites());
      dispatch(getVessels());
    }
  }, [logged]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelectPoi = (item: IMapPois) => {
    if (depthShadingDrawerOpen) {
      dispatch(setDepthShadingDrawerOpen(false));
      dispatch(setDepthShadingToEdit(null));
    }
    if (creatingRoute) {
      if (setPoiToRoute && inputType) setPoiToRoute(item, inputType);
    } else {
      setPoi(item);
      if (onCloseAccordion) onCloseAccordion();
    }
  };

  // Constants to check if we have history items or favorites
  const hasSearchHistory = searchHistory && searchHistory.length > 0;
  const hasFavorites = favorites && favorites.length > 0;
  const hasHomePort = userInformation?.current_vessel && userInformation?.current_vessel.port_name;

  const handleHomePortClick = () => {
    if (!hasHomePort) {
      setAlertDialogMessage("You don't have a Home Port set");
      setOpenAlertDialog(true);
    } else if (creatingRoute) {
      if (setPoiToRoute && inputType)
        setPoiToRoute(
          {
            lat: userInformation?.current_vessel?.lat || 0,
            lng: userInformation?.current_vessel?.lng || 0,
            name: userInformation?.current_vessel?.port_name || "",
          },
          inputType,
        );
    }
  };

  const handleSelectCategory = (item: TPOI_TYPE, action?: boolean) => {
    onSetInputValue(item, action || false);
  };

  const handleConfirmCoordinates = async () => {
    if (depthShadingDrawerOpen) {
      dispatch(setDepthShadingDrawerOpen(false));
      dispatch(setDepthShadingToEdit(null));
    }

    if (pointSearchedByCoords?.lat && pointSearchedByCoords?.lng) {
      const lat = Number(pointSearchedByCoords?.lat);
      const lng = Number(pointSearchedByCoords?.lng);

      const poi = {
        name: `GPS Location ${dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss A")}`,
        lat,
        lng,
        place_type: "my_location",
      };

      dispatch(addSelectedPoi(poi));
      dispatch(addZoom(DEFAULT_ZOOM));
      dispatch(
        addCenter({
          lat,
          lng,
        }),
      );
      dispatch(addPointSearchedByCoords(null));
    }
  };

  const handleConfirmCoordinatesRoute = () => {
    if (pointSearchedByCoords) {
      if (inputsFocus === 1) {
        dispatch(
          addStartPoint({
            lat: pointSearchedByCoords.lat,
            lng: pointSearchedByCoords.lng,
            name: `${pointSearchedByCoords.lat},${pointSearchedByCoords.lng}`,
          }),
        );
      } else {
        dispatch(
          addEndPoint({
            lat: pointSearchedByCoords.lat,
            lng: pointSearchedByCoords.lng,
            name: `${pointSearchedByCoords.lat},${pointSearchedByCoords.lng}`,
          }),
        );
      }
      dispatch(setRoutePointSetted(true));
      dispatch(addPointSearchedByCoords(null));
    }
  };

  return (
    <>
      {hasHomePort && creatingRoute && (
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={false}
          id="panel1a-header"
          sx={{ borderTop: "1px solid", borderColor: "divider" }}
          onClick={handleHomePortClick}
        >
          <Avatar>
            <HomeOutlined />
          </Avatar>

          <Stack direction="column" mt={0.5} spacing={0}>
            <Typography lineHeight="normal" variant="subtitle1">
              Home Port
            </Typography>
            <Typography sx={{ color: "text.secondary" }} variant="caption">
              {userInformation?.current_vessel?.port_name}
            </Typography>
          </Stack>
        </AccordionSummary>
      )}
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel2a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel2a-header"
        >
          <Avatar>
            <History />
          </Avatar>
          <Typography variant="subtitle1">History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding>
            {!hasSearchHistory && (
              <EmptyState
                imageURL="img/placeholders/EmptyStateHistory.svg"
                subtitle="It seems you haven't searched yet. Once you start, your viewing history will appear here."
                title="No History Yet"
              />
            )}
            {hasSearchHistory &&
              searchHistory.map((item: IMapPois, index: number) => (
                <SearchResultListItem
                  key={`history-list-${item.id}`}
                  action={() => handleSelectPoi(item)}
                  icon={findPoi(item?.place_type || item?.poi_type)}
                  id={item.id}
                  numberList={index}
                  primaryLabel={item.name}
                  secondaryLabel={item.address}
                />
              ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary
          aria-controls="panel3a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel3a-header"
        >
          <Avatar>
            <FavoriteBorderOutlined />
          </Avatar>
          <Typography variant="subtitle1">Favorite Places</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding>
            {!hasFavorites && (
              <EmptyState
                imageURL="img/placeholders/EmptyStateFavorite.svg"
                subtitle="Looks like you haven't added any favorites yet. Click the heart icon to add it to your favorites list."
                title="No Favorites Yet"
              />
            )}
            {hasFavorites &&
              favorites.map((item: IMapPois, index: number) => (
                <SearchResultListItem
                  key={`favorite-list-${item?.id}`}
                  action={() => handleSelectPoi(item)}
                  icon={findPoi(item?.place_type || item?.poi_type)}
                  id={item?.id}
                  numberList={index}
                  primaryLabel={item?.name}
                  secondaryLabel={item?.address}
                />
              ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary
          aria-controls="panel4a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Avatar>
            <CategoryOutlined />
          </Avatar>
          <Typography variant="subtitle1">Search Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={handleSelectCategory}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          aria-controls="panel5a-content"
          expandIcon={<ExpandMoreOutlined />}
          id="panel4a-header"
        >
          <Avatar>
            <MyLocation />
          </Avatar>
          <Typography variant="subtitle1">Enter Coordinates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchCoordinatesExpanded
            onConfirmCoordinates={
              creatingRoute ? handleConfirmCoordinatesRoute : handleConfirmCoordinates
            }
          />
        </AccordionDetails>
      </Accordion>
      <AlertDialog
        close={() => setOpenAlertDialog(false)}
        confirm={() => setOpenAlertDialog(false)}
        message={alertDialogMessage}
        open={openAlertDialog}
        positiveButtonTitle="OK"
        title="Search"
      />
    </>
  );
}
