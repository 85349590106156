import { List, ListItem, Button, Stack, FormControlLabel, RadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { styles } from "./styles";
import PremiumSubscriptionRadio from "./PremiumSubscriptionRadio";

import { SubscriptionOptions } from "@/types/premium";
import { useAppSelector } from "@/hooks/useRedux";

interface IPremiumSubscriptionGroup {
  action?: () => void;
}

function PremiumSubscriptionGroup({ action }: IPremiumSubscriptionGroup) {
  const { logged } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionOptions>(
    SubscriptionOptions.USMonthly,
  );

  const handleSetSubscription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSubscription(event.target.value as SubscriptionOptions);
  };

  const isSelected = (option: SubscriptionOptions) => selectedSubscription === option;

  return (
    <Stack>
      <RadioGroup
        aria-labelledby="shadings-types-group-radio"
        value={selectedSubscription}
        onChange={handleSetSubscription}
      >
        <List disablePadding>
          <>
            <ListItem disableGutters sx={styles.listItem}>
              <FormControlLabel
                control={
                  <PremiumSubscriptionRadio
                    subscriptionInfo={{
                      price: "$39.99/year",
                      period: "Yearly",
                      discount: "$83.88/year",
                      description: "Free 2 week trial",
                      discountChip: "Save 40%",
                    }}
                  />
                }
                label={null}
                sx={
                  isSelected(SubscriptionOptions.USYearly)
                    ? styles.formControlLabelSelected
                    : styles.formControlLabel
                }
                value={SubscriptionOptions.USYearly}
              />
            </ListItem>
            <ListItem disableGutters sx={styles.listItem}>
              <FormControlLabel
                control={
                  <PremiumSubscriptionRadio
                    subscriptionInfo={{
                      price: "$6.99/month",
                      period: "Monthly",
                      description: "Free 2 week trial",
                    }}
                  />
                }
                label={null}
                sx={
                  isSelected(SubscriptionOptions.USMonthly)
                    ? styles.formControlLabelSelected
                    : styles.formControlLabel
                }
                value={SubscriptionOptions.USMonthly}
              />
            </ListItem>
          </>
        </List>
      </RadioGroup>
      <Stack spacing={1}>
        <Button
          fullWidth
          sx={styles.mainButton}
          variant="contained"
          onClick={() => {
            if (!logged) {
              navigate("/login");
            } else {
              navigate(`/premium-payment?type_subscription=${selectedSubscription}`);
              if (action) action();
            }
          }}
        >
          TRY FOR FREE
        </Button>
      </Stack>
    </Stack>
  );
}

export default PremiumSubscriptionGroup;
