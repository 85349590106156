/* global GeolocationPosition */
import {
  Box,
  Typography,
  Stack,
  Rating,
  Divider,
  Button,
  List,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  LocationOn,
  Phone,
  PublicOutlined,
  Mail,
  AttachMoneyOutlined,
  AccessTimeOutlined,
  Place,
  TripOriginOutlined,
  Lock,
  MoreVert,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";
import EditIcon from "@mui/icons-material/Edit";

import FavoriteCheckbox from "../FavoriteCheckbox";
import BaseModal from "../Modals/BaseModal";

import { styles } from "./styles";
import PlaceInfoListItem from "./PlaceInfoListItem";
import PlaceInfoSkeleton from "./PlaceInfoSkeleton/PlaceInfoSkeleton";
import PlaceInfoDescription from "./PlaceInfoSections/PlaceInfoDescription";
import PlaceInfoFuel from "./PlaceInfoSections/PlaceInfoFuel";
import PlaceInfoTransient from "./PlaceInfoSections/PlaceInfoTransient";
import PlaceInfoIconType from "./PlaceInfoIconType";
import PlaceInfoAmenities from "./PlaceInfoSections/PlaceInfoAmenities";
import PlaceInfoApproach from "./PlaceInfoSections/PlaceInfoApproach";
import PlaceInfoPrivateDock from "./PlaceInfoSections/PlaceInfoPrivateDock";

import { IMapPois } from "@/types/pois";
import PlaceInfoReviews from "@/components/PlaceInfo/PlaceInfoSections/PlaceInfoReviews";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { getPosition } from "@/utils/maps";
import { Props } from "@/types/users";
import { Position } from "@/types/map";
import { getDistanceFromLocation, amenitiesTransientVisibility, fuelVisibility } from "@/utils/poi";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import ServiceUser from "@/services/user";
import { getFavoritesSuccess } from "@/store/slices/user/actions";
import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as VHFIcon } from "&/img/icons/VHF_iconFilled.svg";
import { ReactComponent as CalendarAltIcon } from "&/img/icons/CalendarAlt_iconFilled.svg";
import { ReactComponent as DepthIcon } from "&/img/icons/Depth_iconFilled.svg";
import { ReactComponent as BridgeIcon } from "&/img/icons/bridge_iconFilled.svg";
import { ReactComponent as VerticalIcon } from "&/img/icons/vertical_iconFilled.svg";
import { ReactComponent as InfoIcon } from "&/img/icons/info_iconFilled.svg";
import usePremiumUser from "@/hooks/usePremiumUser";
import dayjs from "@/config/dayjs";
import { setTempPin } from "@/store/slices/reportpins";
import useDisclosure from "@/hooks/useDisclosure";
import servicePins from "@/services/pins";
import { useGetPlacesQuery } from "@/store/api/argoApi";
import { addSelectedPoi } from "@/store/slices/search";

const PLACE_LOCATION_TYPE = "my_location";
const KEY_ADMIN_PRIVILEGE = "admin";
const KEY_PLACE_MARINA = "Marina";
const KEY_PLACE_BRIDGE = "Bridge";
const KEY_PLACE_LOCK = "Lock";

export type TParamsLocation = {
  poi_id?: number;
  place_id?: number;
  name?: string;
  pin_type?: string;
  lat?: string;
  lng?: string;
  description?: string;
};

type TPaymenTAvailable = {
  credit_cards: string | null;
  checks: string | null;
  payment_discount: string | null;
};

export default function PlaceInfo({ close, isDrawer, poiSelected, onCreateOpenRoute }: Props) {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<Position | null>(null);
  const [checkedFavorite, setCheckedFavorite] = useState<boolean>(false);
  const [recentGpsPoi, setRecentGpsPoi] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAppSelector((state) => state.auth);
  const { favorites, userInformation } = useAppSelector((state) => state.user);
  const isPremium = usePremiumUser();
  const { distanceUnitHandler, coordinatesFormatHandler } = useUnitSystem();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { refetch: refetchPlaces } = useGetPlacesQuery({});
  const { onOpen, isOpen, onClose } = useDisclosure();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const distanceAway = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(
      getDistanceFromLocation(position || { lat: 0, lng: 0 }, {
        lat: poiSelected?.lat,
        lng: poiSelected?.lng,
      }),
    ),
  });

  const isFuelVisible = fuelVisibility(poiSelected);
  const isAmenitiesTransientVisible = amenitiesTransientVisibility(poiSelected);

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
    setLoading(false);
  };

  const handleCheckFavorite = () => {
    if (poiSelected) {
      if (poiSelected?.place_type) {
        if (poiSelected?.favorite) {
          setCheckedFavorite(true);
        } else {
          setCheckedFavorite(false);
        }
      } else if (favorites.some((item) => item?.id === poiSelected?.id)) {
        setCheckedFavorite(true);
      } else {
        setCheckedFavorite(false);
      }
    }
  };

  useEffect(() => {
    handleCheckFavorite();
  }, [favorites, poiSelected]);

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
    setLoading(false);
  }, [poiSelected]);

  const handleCheckPaymentMode = () => {
    let paymentAvailable: TPaymenTAvailable = {
      credit_cards: null,
      checks: null,
      payment_discount: null,
    };

    if (poiSelected?.payment_discount || poiSelected?.checks || poiSelected?.credit_cards) {
      if (poiSelected?.credit_cards) {
        paymentAvailable = {
          ...paymentAvailable,
          credit_cards: `Credit Cards`,
        };
      }

      if (poiSelected?.checks) {
        paymentAvailable = {
          ...paymentAvailable,
          checks: `Checks`,
        };
      }
      if (poiSelected?.payment_discount) {
        paymentAvailable = {
          ...paymentAvailable,
          payment_discount: poiSelected?.payment_discount,
        };
      }

      return paymentAvailable;
    }

    return null;
  };

  const PlaceInfoListItems = [
    {
      id: 1,
      icon: <LocationOn />,
      label: poiSelected?.address,
    },
    {
      id: 2,
      icon: <BaseIcon icon={BridgeIcon} />,
      label: poiSelected?.bridge_type,
    },
    {
      id: 3,
      icon: <BaseIcon icon={VerticalIcon} />,
      label:
        poiSelected?.ver_clr || poiSelected?.open_ver_clr || poiSelected?.hor_clr ? (
          <Stack>
            {poiSelected?.ver_clr && (
              <Typography>Vertical Clearance: {poiSelected?.ver_clr} ft</Typography>
            )}
            {poiSelected?.open_ver_clr && (
              <Typography>Open Vertical Clearance: {poiSelected?.open_ver_clr} ft</Typography>
            )}
            {poiSelected?.hor_clr && (
              <Typography>Horizontal Clearance: {poiSelected?.hor_clr} ft</Typography>
            )}
          </Stack>
        ) : null,
    },
    {
      id: 4,
      icon: <BaseIcon icon={VerticalIcon} />,
      label:
        poiSelected?.number_of_chamber || poiSelected?.lock_size ? (
          <Stack>
            {poiSelected?.number_of_chamber && (
              <Typography>Number of Chambers: {poiSelected?.number_of_chamber}</Typography>
            )}
            {poiSelected?.lock_size && <Typography>Size: {poiSelected?.lock_size}</Typography>}
          </Stack>
        ) : null,
    },
    {
      id: 5,
      icon: <AccessTimeOutlined />,
      label: poiSelected?.operation_hour ? (
        <Stack>
          <Typography>{`${poiSelected?.operation_hour}, ${poiSelected?.operation}`}</Typography>
          <Typography>After hours: {poiSelected?.after_hours_contact}</Typography>
        </Stack>
      ) : null,
    },
    {
      id: 6,
      icon: <Phone />,
      label: poiSelected?.phone,
    },
    {
      id: 7,
      icon: <BaseIcon icon={VHFIcon} />,
      label: poiSelected?.vhf_channel,
    },
    {
      id: 8,
      icon: <PublicOutlined />,
      label: poiSelected?.website,
    },
    {
      id: 9,
      icon: <Mail />,
      label: poiSelected?.email,
    },
    {
      id: 10,
      icon: <AttachMoneyOutlined />,
      label: (poiSelected?.credit_cards ||
        poiSelected?.checks ||
        poiSelected?.payment_discount) && (
        <Stack>
          <Typography>
            {handleCheckPaymentMode()?.credit_cards || ""}
            {handleCheckPaymentMode()?.credit_cards && handleCheckPaymentMode()?.checks ? ", " : ""}
            {handleCheckPaymentMode()?.checks || ""}
          </Typography>
          <Typography>{handleCheckPaymentMode()?.payment_discount}</Typography>
        </Stack>
      ),
    },
    {
      id: 11,
      icon: <BaseIcon icon={CalendarAltIcon} />,
      label: poiSelected?.date_deployed ? `Date Deployed: ${poiSelected?.date_deployed}` : null,
    },
    {
      id: 12,
      icon: <BaseIcon icon={DepthIcon} />,
      label:
        poiSelected?.poi_type === "Artificial Reef" && poiSelected?.depth
          ? `Depth: ${poiSelected?.depth}`
          : null,
    },
    {
      id: 13,
      icon: <BaseIcon icon={CalendarAltIcon} />,
      label: poiSelected?.bridge_schedule || poiSelected?.lock_schedule,
    },
    {
      id: 14,
      icon: <BaseIcon icon={InfoIcon} />,
      label: poiSelected?.bridge_other_info || poiSelected?.lock_other_info,
    },
  ];

  const handleSetFavorite = async () => {
    let params: TParamsLocation = {};

    if (!poiSelected?.id && poiSelected?.place_type === PLACE_LOCATION_TYPE) {
      try {
        const dataBody = {
          name: poiSelected?.name,
          lat: poiSelected?.lat,
          lng: poiSelected?.lng,
          pin_type: "place",
          place_type: "gps_location",
        };

        const { status, data: createdPinData } = await servicePins.createPin(dataBody);

        if (status === 201) {
          refetchPlaces();

          params = {
            place_id: createdPinData.data?.id,
          };

          const { status: favoritedStatus, data: favoritedData } = await ServiceUser.setFavorite(
            params,
          );

          if (favoritedStatus === 200) {
            if (favoritedData?.place_type) {
              dispatch(addSelectedPoi({ ...createdPinData.data, favorite: true }));
            }
            setRecentGpsPoi(favoritedData);
            dispatch(getFavoritesSuccess([...favorites, poiSelected]));
            dispatch(
              setSnackBarMsjSucceded({
                state: true,
                type: "success",
                msj: "Place added to favorites.",
              }),
            );
          }
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    } else {
      try {
        if (poiSelected?.id) {
          if (poiSelected?.place_type) {
            params = {
              place_id: poiSelected?.id,
            };
          } else {
            params = {
              poi_id: poiSelected?.id,
            };
          }
        } else {
          params = {
            name: poiSelected?.name,
            pin_type: PLACE_LOCATION_TYPE,
            lat: poiSelected?.lat,
            lng: poiSelected?.lng,
            description: "",
          };
        }

        const { status, data } = await ServiceUser.setFavorite(params);

        if (status === 200) {
          if (poiSelected?.place_type) {
            dispatch(addSelectedPoi({ ...poiSelected, favorite: true }));
          }
          setRecentGpsPoi(data);
          dispatch(getFavoritesSuccess([...favorites, poiSelected]));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Place added to favorites.",
            }),
          );
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const handleRemoveFavorite = async () => {
    try {
      let params = {};

      if (poiSelected.id && poiSelected.place_type !== PLACE_LOCATION_TYPE) {
        if (poiSelected?.place_type) {
          params = {
            place_id: poiSelected?.id,
          };
        } else {
          params = {
            poi_id: poiSelected?.id,
          };
        }
      } else {
        params = {
          place_id: poiSelected.id || recentGpsPoi.id,
        };
      }

      const { status } = await ServiceUser.removeFavorite(params);

      if (status === 200) {
        if (poiSelected?.place_type) {
          dispatch(addSelectedPoi({ ...poiSelected, favorite: false }));
        }
        dispatch(getFavoritesSuccess(favorites.filter((item) => item.id !== poiSelected.id)));
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Place removed from favorites.",
          }),
        );
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const isSubmit = poiSelected?.submit !== undefined;

  const previous: dayjs.Dayjs = dayjs(poiSelected.created_at);

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(poiSelected?.lat),
    longitude: Number(poiSelected?.lng),
  });

  const handleEditPin = (poiSelected: IMapPois | undefined) => {
    if (poiSelected) {
      dispatch(addSelectedPoi(null));
      dispatch(
        setTempPin({
          value: poiSelected?.place_type,
          location: { lat: poiSelected?.lat, lng: poiSelected?.lng },
          editPinInfo: poiSelected,
        }),
      );
    }
  };

  const handleOpenEditPoi = () => {
    dispatch(addSelectedPoi({ ...poiSelected, editMode: true }));
  };

  const handleDeletePlacePin = async (placePinId: number) => {
    if (placePinId) {
      try {
        const { status } = await servicePins.deletePlace(placePinId);

        if (status === 200) {
          refetchPlaces();
          dispatch(addSelectedPoi(null));
          dispatch(setTempPin(null));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Place Pin was deleted.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const selectedToEdit =
    poiSelected?.poi_type === KEY_PLACE_MARINA ||
    poiSelected?.poi_type === KEY_PLACE_BRIDGE ||
    poiSelected?.poi_type === KEY_PLACE_LOCK;

  return (
    <Box sx={isDrawer ? styles.mainContainerDrawer : styles.mainContainer}>
      {!isDrawer && (
        <Button
          aria-label="delete"
          color="whiteBg"
          size="small"
          sx={styles.closeButton}
          variant="iconButtonContained"
          onClick={close}
        >
          <CloseOutlined />
        </Button>
      )}

      {loading ? (
        <PlaceInfoSkeleton />
      ) : (
        <>
          <Stack pb={2} pl={2} pr={2} pt={!isDrawer ? 1 : 2} spacing={1}>
            <Stack alignItems="flex-start" className="titleContainer" spacing={0.5}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <PlaceInfoIconType poiType={poiSelected?.poi_type || poiSelected?.place_type} />
                {poiSelected?.user_id === userInformation?.id && (
                  <Box>
                    <IconButton
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      aria-label="more"
                      id="long-button"
                      onClick={handleClick}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      id="long-menu"
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleEditPin(poiSelected)}>
                        <ListItemIcon>
                          <EditOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={onOpen}>
                        <ListItemIcon>
                          <DeleteOutline fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
                {user?.privilege === KEY_ADMIN_PRIVILEGE && selectedToEdit && (
                  <Box>
                    {isDrawer ? (
                      <Button
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEditPoi}
                      >
                        Edit Info
                      </Button>
                    ) : (
                      <IconButton
                        sx={{ mr: 3, width: 28, height: 28, mt: 0.7 }}
                        onClick={handleOpenEditPoi}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Stack>
              <Typography component="h2" sx={styles.poiTitle} variant="h5">
                {poiSelected?.name}
              </Typography>
            </Stack>
            <Stack>
              {poiSelected?.poi_ratings_count ? (
                <Stack alignItems="center" direction="row" mb={0.5} spacing={1}>
                  <Rating
                    readOnly
                    name="rating-place"
                    precision={0.5}
                    value={poiSelected?.poi_ratings_overall}
                  />
                  <Typography
                    component="h3"
                    variant="body1"
                  >{`${poiSelected?.poi_ratings_count} reviews`}</Typography>
                </Stack>
              ) : (
                ""
              )}
              {poiSelected?.poi_type !== "Private Dock" && (
                <Typography component="h3" variant="subtitle2">{`${latLngFormatted}`}</Typography>
              )}

              <Stack alignItems="center" direction="row" spacing={1}>
                {isSubmit && (
                  <Typography color="text.secondary" component="h3" variant="body2">
                    {dayjs(previous).fromNow()}
                  </Typography>
                )}
                {((isSubmit && position) ||
                  (isSubmit && poiSelected?.submit) ||
                  (isSubmit && !poiSelected?.submit)) && (
                  <Typography color="text.secondary" component="h3" variant="body2">
                    •
                  </Typography>
                )}
                {position && (
                  <Typography color="text.secondary" component="h3" variant="body2">
                    {`${distanceAway.value} ${distanceAway.unit} away  `}
                  </Typography>
                )}
                {isSubmit && poiSelected?.submit && (
                  <>
                    <Typography color="text.secondary" component="h3" variant="body2">
                      •
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <AccessTimeFilledIcon sx={{ width: 16, color: "text.secondary" }} />{" "}
                      <Typography color="text.secondary" component="h3" variant="body2">
                        Under Review
                      </Typography>
                    </Stack>
                  </>
                )}
                {isSubmit && !poiSelected?.submit && (
                  <>
                    <Typography color="text.secondary" component="h3" variant="body2">
                      •
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <VisibilityOffIcon sx={{ width: 16, color: "text.secondary" }} />{" "}
                      <Typography color="text.secondary" component="h3" variant="body2">
                        Private
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack alignItems="center" direction="row" justifyContent="space-between" p={2}>
            <Stack direction="row" spacing={1}>
              <Button
                className="buttonFrom"
                disabled={!isPremium}
                endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
                size="medium"
                sx={styles.routeButton}
                variant="outlined"
                onClick={() => onCreateOpenRoute(poiSelected, 1)}
              >
                Route from
              </Button>
              <Button
                className="buttonTo"
                disabled={!isPremium}
                endIcon={isPremium ? <Place /> : <Lock />}
                size="medium"
                sx={styles.routeButton}
                variant="outlined"
                onClick={() => onCreateOpenRoute(poiSelected, 2)}
              >
                Route to
              </Button>
            </Stack>
            <Stack alignItems="center" direction="row" spacing={1}>
              <FavoriteCheckbox
                checked={checkedFavorite}
                onClick={checkedFavorite ? handleRemoveFavorite : handleSetFavorite}
              />
            </Stack>
          </Stack>
          <Divider />

          {(poiSelected?.description ||
            poiSelected?.number_of_slips ||
            poiSelected?.clean_marina ||
            poiSelected?.assets?.[0]?.url ||
            poiSelected?.assets?.[0]?.download_url) && (
            <PlaceInfoDescription poiSelected={poiSelected} />
          )}

          <List disablePadding>
            {PlaceInfoListItems.map((item) => {
              if (item?.label) {
                return (
                  <PlaceInfoListItem
                    key={item?.id}
                    divider
                    icon={item?.icon}
                    primary={item?.label}
                  />
                );
              }

              return null;
            })}
          </List>
          {/* Private Dock */}
          {poiSelected?.poi_type === "Private Dock" && (
            <PlaceInfoPrivateDock poiSelected={poiSelected} />
          )}
          {/* Fuel */}
          {isFuelVisible && <PlaceInfoFuel poiSelected={poiSelected} />}

          {/* Amenities */}
          {isAmenitiesTransientVisible && <PlaceInfoAmenities poiSelected={poiSelected} />}

          {/* Transient Slips/Moorings */}
          {isAmenitiesTransientVisible && <PlaceInfoTransient poiSelected={poiSelected} />}

          {/* Approach */}
          {isAmenitiesTransientVisible && <PlaceInfoApproach poiSelected={poiSelected} />}

          {/* Component to show reviews for POI'S */}
          {!("place_type" in poiSelected) && <PlaceInfoReviews poiSelected={poiSelected} />}
        </>
      )}

      {isOpen && (
        <BaseModal
          ariaDescribedby="Delete Place Pin"
          ariaLabelledby="Delete Place Pin"
          open={isOpen}
          size="xs"
          title="Delete Place Pin"
          onClose={onClose}
        >
          <Stack pb={2} pt={1} px={3} spacing={2}>
            <Typography color="text.primary" variant="body1">
              Are you sure you want to delete this place pin?
            </Typography>

            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button type="submit" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => handleDeletePlacePin(poiSelected?.id)}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      )}
    </Box>
  );
}
