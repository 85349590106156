import { initialState } from "./index";

import { AppDispatch } from "@/store/reducers";
import voyagesServices from "@/services/voyages";
// import { getZoomForRoute, fitToRegion } from "@/utils/maps";
import { evaluateRoutePaddingByResolution, findBounds } from "@/utils/maps";
// import { addCenter, addZoom } from "@/store/slices/map";
import { setSnackBarMsj, setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { IVoyage, IVoyages } from "@/types/route";
import { Position } from "@/types/map";

const getVoyagesStarted = (value: boolean) => ({
  type: "captainsLog/fetchStarted",
  value,
});

const getVoyagesFailed = (error: any) => ({
  type: "captainsLog/fetchFailed",
  error,
});

const getVoyagesSuccess = (payload: any) => ({
  type: "captainsLog/addVoyages",
  payload,
});

export const setSelectedVoyage = (payload: any) => ({
  type: "captainsLog/addSelectedVoyage",
  payload,
});

export const setRoutesForVoyage = (payload: any) => ({
  type: "captainsLog/addCourses",
  payload,
});

export const getVoyage = (id: number, map?: any) => async (dispatch: AppDispatch) => {
  try {
    const { status, data } = await voyagesServices.getVoyageById(id);

    if (status === 200) {
      const { courses } = data;

      // const routeCenter = fitToRegion(courses[0].course_locations);

      dispatch(setRoutesForVoyage(courses));
      dispatch(setSelectedVoyage(data));

      if (map && courses.length > 0 && courses[0]?.course_locations) {
        const bounds = findBounds(
          courses[0]?.course_locations?.map((course: Position) => ({
            ...course,
            lat: Number(course.lat),
            lng: Number(course.lng),
          })),
        );

        if (bounds) {
          const screenWidth = window.innerWidth;

          const mapBoundsConfig = evaluateRoutePaddingByResolution(screenWidth);

          map.fitBounds(
            {
              east: bounds.eastmost.lng,
              north: bounds.northmost.lat,
              south: bounds.southmost.lat,
              west: bounds.westmost.lng,
            },
            mapBoundsConfig.boundsPadding,
          );
        }
      }

      return true;
    }

    return false;
  } catch (error: any) {
    dispatch(getVoyagesFailed(error.toString()));
    dispatch(getVoyagesStarted(false));
    dispatch(setSnackBarMsj(true, "error", error.toString()));

    return false;
  }
};

export const getVoyages =
  (page: number, keyword: string, sortFilters: string, favorites: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(getVoyagesStarted(true));

    const params = {
      voyage_type: "route",
      order: sortFilters,
      favorites,
      keyword,
      page,
    };

    try {
      const { status, data } = await voyagesServices.getVoyages(params);

      if (status === 200) {
        dispatch(getVoyagesSuccess(data));
        dispatch(getVoyagesStarted(false));

        return true;
      }

      return false;
    } catch (error: any) {
      dispatch(getVoyagesFailed(error.toString()));
      dispatch(getVoyagesStarted(false));
      dispatch(setSnackBarMsj(true, "error", error.toString()));

      return false;
    }
  };

export const checkForMoreItems =
  (page: number, keyword: string, sortFilters: string, favorites: boolean) =>
  async (dispatch: AppDispatch) => {
    const params = {
      voyage_type: "route",
      order: sortFilters,
      favorites,
      keyword,
      page,
    };

    try {
      const { status, data } = await voyagesServices.getVoyages(params);

      if (status === 200) {
        if (data.items.length > 0) {
          return true;
        }

        return false;
      }

      return false;
    } catch (error: any) {
      dispatch(getVoyagesFailed(error.toString()));
      dispatch(getVoyagesStarted(false));
      dispatch(setSnackBarMsj(true, "error", error.toString()));

      return false;
    }
  };

export const deleteVoyage = (voyages: any, voyageId: number) => async (dispatch: AppDispatch) => {
  try {
    const { status } = await voyagesServices.deleteVoyage(voyageId);

    if (status === 204 && voyages) {
      const newVoyagesList = voyages.items.filter((item: IVoyage) => item.id !== voyageId);

      dispatch(
        getVoyagesSuccess({
          ...voyages,
          total_items: voyages.total_items - 1,
          items: newVoyagesList,
        }),
      );

      dispatch(setSelectedVoyage(null));
      dispatch(setRoutesForVoyage(null));

      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "success",
          msj: "Your route was deleted.",
        }),
      );

      return true;
    }

    return false;
  } catch (error: any) {
    dispatch(getVoyagesFailed(error.toString()));
    dispatch(setSnackBarMsj(true, "error", error.toString()));

    return false;
  }
};

export const voyageFavorite =
  (voyages: IVoyages | null, voyageId: number, favorite: boolean) =>
  async (dispatch: AppDispatch) => {
    try {
      if (favorite) {
        const { status } = await voyagesServices.removeVoyageToFavorites(voyageId);

        if (status === 204 && voyages) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Your route was removed from favorites.",
            }),
          );

          return true;
        }
      } else {
        const { status } = await voyagesServices.addVoyageToFavorites(voyageId);

        if (status === 204 && voyages) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Your route was added to favorites.",
            }),
          );

          return true;
        }
      }

      return false;
    } catch (error: any) {
      dispatch(getVoyagesFailed(error.toString()));
      dispatch(setSnackBarMsj(true, "error", error.toString()));

      return false;
    }
  };

export const resetCaptainsLogs = () => async (dispatch: AppDispatch) => {
  dispatch(getVoyagesSuccess(initialState.voyages));
  dispatch(setSelectedVoyage(initialState.selectedVoyage));
  dispatch(setRoutesForVoyage(initialState.courses));
};
